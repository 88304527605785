import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "../../../components/Modal/Template/index";
import SweetAlert2 from 'react-sweetalert2';
import Gateways from '../../../gateways/api';
import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  FlowContainer,
  Button,
  ContainerButton,
} from "./styles";

const TEMPLATE_STATUS = {"APPROVED": "Aprobado", "PENDING":"Pendiente", "REJECTED":"Rechazado" };
const TEMPLATE_CATEGORY = {"UTILITY": "Utilidad", "MARKETING":"Marketing"}

export const ListaPlantillas = () => {
  const [swalProps, setSwalProps] = useState({});
  const [listTemplates, setlistTemplates] = useState([]);
  const [modelOpen, setHandleModelOpen] = useState(false);
  const [listBussiness, setlistBussiness] = useState([{ value: "482726501591641", name: "Clarus Dent" }]);
  const Fila = ({ item }) => {
    return (
      <tr>
        <TablaBodyTexto data-label="Nombre">{item.name}</TablaBodyTexto>
        <TablaBodyTexto data-label="Estado">{TEMPLATE_STATUS[item.status]}</TablaBodyTexto>
        <TablaBodyTexto data-label="Categoria">{TEMPLATE_CATEGORY[item.category]}</TablaBodyTexto>
        <TablaBodyTexto data-label="Lenguaje">{item.language}</TablaBodyTexto>
      </tr>
    );
  };

  const getAllTemplate = async (businessID) => {
    try {
      let { data } = await Gateways.MetaWhatsAppApi.getAllTemplatesByBusinessId(businessID);
      return data;
    } catch (error) {
      throw new Error('Error fetching templates: ' + error.message);
    }
  }


  
  const getAllT = (businessID) => {
    const listTemplate = getAllTemplate(businessID);
    listTemplate.then((item) => {
      setlistTemplates(item);
    });
  }

  if (listTemplates.length == 0) {
    getAllT(listBussiness[0].value);
  }

  //#endregion
  const handleModelOpen = () => {
    setHandleModelOpen(true);
  };

  const handleModelClose = () => {
    setHandleModelOpen(false);
  };

  const onhandleSave = (event) => {
    setHandleModelOpen(!event);
    setSwalProps({
      show: true,
      title: '¡Guardado!',
      text: 'Se registro correctamenta la plantilla',
      icon: 'success',
      confirmButtonText: 'Aceptar',
      onConfirm: () => {
        setSwalProps({ show: false });
      }
    });
  };


  const history = useHistory();
  const sesion = useSelector((state) => state.sesion);
  if (sesion.loading || sesion.loading == undefined)
    return <div>...cargando</div>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  else
    return (
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Administrador de Plantillas</ContainerTitleText>
        </ContainerTitle>
        <FlowContainer>
          <ContainerButton>
            <select name="category" class="form-select form-control" aria-label="Default select example" onChange={(e) => getAllT(e.target.value)}>
              {listBussiness.map((item) => {
                return (<option value={item.value} >{item.name}</option>)
              })}
            </select>
          </ContainerButton>
          <ContainerButton>
            <Button onClick={() => handleModelOpen()}>
              Crear
            </Button>
          </ContainerButton>
        </FlowContainer>

        <ContainerBody>
          <Tabla>
            <TablaHeader>
              <tr>
                <TablaHeaderTexto scope="col">Nombre Plantilla</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Estado</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Categoria</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Idioma</TablaHeaderTexto>
              </tr>
            </TablaHeader>
            <TablaBody>
              {listTemplates.map((item) => {
                return (
                  <Fila
                    key={item.id}
                    item={item}
                  />
                );
              })}
            </TablaBody>
          </Tabla>
        </ContainerBody>

        <Modal isOpen={modelOpen} title="Crear Plantilla" onClose={handleModelClose}  onhandleSave={onhandleSave} />
        <SweetAlert2 {...swalProps} />
      </MainContainer>

    );

};

