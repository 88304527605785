import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "../../../components/Modal/Enviar/index";
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Gateways from '../../../gateways/api';
import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  TableRow
} from "./styles";
const STATE_NOT_VERIFIED = "NOT_VERIFIED";

export const ListaEmpresas = () => {
  const [modelOpen, setHandleModelOpen] = useState(false);
  const [phoneID, setPhoneID] = useState();
  const [phone, setPhone] = useState();
  const [businessID, SetbusinessID] = useState();
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const META_CLOUD_BUSINESS_ACCOUNT_ID = "482726501591641";

  const sesion = useSelector((state) => state.sesion);
  const Fila = ({ item }) => {
    return (
      <TableRow>
        <TablaBodyTexto data-label="Nombre">{item.verified_name}</TablaBodyTexto>
        <TablaBodyTexto data-label="Estado">{item.display_phone_number}</TablaBodyTexto>
        <TablaBodyTexto data-label="Categoria">{item.id}</TablaBodyTexto>
        <TablaBodyTexto data-label="Categoria">{item.code_verification_status === STATE_NOT_VERIFIED ? "No verificado" : "Verificado"}</TablaBodyTexto>
        <TablaBodyTexto data-label="Opciones">
          <IconButton onClick={() => handleModelOpen(item.id, META_CLOUD_BUSINESS_ACCOUNT_ID, item.display_phone_number)} color="primary" disabled={item.code_verification_status === STATE_NOT_VERIFIED}>
            <SendIcon />
          </IconButton>
        </TablaBodyTexto>
      </TableRow>
    );
  };

  const handleModelOpen = (phoneID, businessID, phone) => {
    setHandleModelOpen(true);
    setPhoneID(phoneID);
    setPhone(phone);
    SetbusinessID(businessID);
  };

  const handleModelClose = () => {
    setHandleModelOpen(false);
  };

  const getAllBusiness = async () => {
    try {
      return await Gateways.MetaWhatsAppApi.getAllBusinessRegistered();
    } catch (error) {
      throw new Error('Error fetching templates: ' + error.message);
    }
  }
  React.useEffect(() => {
    getAllBusiness().then(item => {
      setListaEmpresas(item.data);
    });
  }, []);


  if (sesion.loading || sesion.loading == undefined)
    return <div>...cargando</div>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  else
    return (
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Administrador de Empresas</ContainerTitleText>
        </ContainerTitle>
        <ContainerBody>
          <Tabla>
            <TablaHeader>
              <tr>
                <TablaHeaderTexto scope="col">Empresa</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Telefono</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">EmpresaID</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Estado</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Opciones</TablaHeaderTexto>
              </tr>
            </TablaHeader>
            <TablaBody>
              {listaEmpresas.map((item) => {
                return (
                  <Fila
                    key={item.id}
                    item={item} />
                );
              })}
            </TablaBody>
          </Tabla>
        </ContainerBody>
        {modelOpen && <Modal isOpen={modelOpen} phoneId={phoneID} businessID={businessID} phone={phone} title="WhatsApp mensaje plantilla" onClose={handleModelClose} />}
      </MainContainer>

    );
};

