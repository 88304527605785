const BASE_URL = "http://localhost:3005"
const API_URL = process.env.REACT_APP_API_URL||"https://us-central1-odontoplus-4db47.cloudfunctions.net"
const API_META_WHATSAPP = "https://graph.facebook.com/v19.0"
const TOKEN_META_WHATSAPP = "EAAGXQ8gHSS0BO3yVLzQWeP3WufBdxWg9W1vJ3VkjTJ0hoXvoS7mS6Fk8jg3RJcUerYxVIV5VXZAzVvyfccHPi7u12xfrNydDEeqXAeqZAZA325YkbujAZB6vSZCfebxX6qMsi13Bvcv5HjZBM8eylHGSyAGBG9txSWPKY0AazoZAZB4IctgmavxIb6t0ZC6ORphgtVQZDZD"
const META_CLOUD_BUSINESS_ACCOUNT_ID= "482726501591641"

let headers = {
  "Content-Type": "application/json;charset=utf-8",
  Accept: "application/json",
};

async function callApi(endpoint, options = {}) {
  options.headers = headers
  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

const serialize = (object) => {
  var str = [];
  for (var p in object)
    if (object.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(object[p]));
    }
  return str.join("&");
}
const httpPostPromise = (url, payload, authorization = '') => {
  headers.Authorization = `Bearer ${authorization}`
  return new Promise((result, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(payload) })
      .then(r => r.json())
      .then(r => result(r))
      .catch(e => reject(e))
  })
}
const httpGetPromise = (url, payload, authorization = '') => {
 headers.Authorization = `Bearer ${authorization}`
  return new Promise((result, reject) => {
    let params = serialize(payload);
    fetch(`${url}?${params}`, { method: 'GET', headers })
      .then(r => r.json())
      .then(r => result(r))
      .catch(e => reject(e))
  })
}

const api = {
  pacientes: {
    getAll() {
      return callApi("/pacientes");
    },
    getPacienteById() { },
    createPaciente() { },
    updatePaciente() { },
  },
  especialistas: {
    getAll() {
      return callApi("/especialistas");
    },
    getPacienteById() { },
    createPaciente() { },
    updatePaciente() { },
  },
  recursos: {
    getAll() {
      return callApi("/recursos");
    },
  },
  prestaciones: {
    getAll() {
      return callApi("/prestaciones");
    },
  },
  WhatsApp: {
    enviarMensaje(payload) {
      return httpPostPromise(`${API_URL}/api/v1/whatsappV2/send-message`, payload);
    },
    obtenerClientes(params) {
      return httpGetPromise(`${API_URL}/api/v1/whatsappV2/get-clients`,params);
    }
  },
  Make: {
    getClientsByBussinesId(params) {
      return httpGetPromise(`https://hook.eu2.make.com/ir6vk0sbir3u1r3jv9g6onoqpy3f5iou`, params);
    }
  },
  MetaWhatsAppApi: {
    crearPlantilla(payload, businessID) {
      return httpPostPromise(`${API_META_WHATSAPP}/${businessID}/message_templates`, payload, TOKEN_META_WHATSAPP);
    },
    getAllTemplatesByBusinessId(businessID){

      console.log("BUSINESS: ",businessID )

      return httpGetPromise(`${API_META_WHATSAPP}/${businessID}/message_templates`,{},TOKEN_META_WHATSAPP)
    },
    getAllBusinessRegistered(){
      return httpGetPromise(`${API_META_WHATSAPP}/${META_CLOUD_BUSINESS_ACCOUNT_ID}/phone_numbers`,{},TOKEN_META_WHATSAPP)
    }
  },

};
export default api;
